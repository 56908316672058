import { Hidden } from "@mui/material";

export default function ChatBanner({ event, currentEvent }) {
    // const { chatRoom, chatVersion } = event;
    // const chatRoot = "http://localhost:5173"; // https://restartix-chat.web.app
    // const chatUrl = `${chatRoot}/rooms/${chatRoom}?name=${name}&v=${
    //     chatVersion ?? "0.0.2"
    // }`;

    if (currentEvent?.offerImageMobile && currentEvent?.offerVisible) {
        return (
            <Hidden mdUp>
                {/* <div style={{ marginTop: 20, fontWeight: "bold" }}>
                Oferta valabilă încă
              </div>
              <Countdown
                date={event.endDate.toDate()}
                renderer={countdownRenderer}
              /> */}

                <a
                    href={currentEvent?.offerUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={currentEvent.offerImageMobile} alt="Oferta" />
                </a>
            </Hidden>
        );
    }

    if (currentEvent?.offerImage && currentEvent?.offerVisible) {
        return (
            <Hidden mdDown>
                {/* <div style={{ marginTop: 20, fontWeight: "bold" }}>
Oferta valabilă încă
</div>
<Countdown
date={event.endDate.toDate()}
renderer={countdownRenderer}
/> */}

                <a
                    href={currentEvent?.offerUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={currentEvent.offerImage} alt="Oferta" />
                </a>
            </Hidden>
        );
    }

    return null;
}
