import { useRef } from "react";

export default function ChatWidget({ name, event }) {
    const { chatRoom, chatVersion } = event;
    const chatEl = useRef(null);
    const chatRoot = "https://restartix-chat.web.app"; // http://localhost:5173
    const chatUrl = `${chatRoot}/rooms/${chatRoom}?name=${name}&v=${
        chatVersion ?? "0.0.2"
    }`;

    return (
        <iframe
            title="Event"
            ref={chatEl}
            src={chatUrl}
            style={{ height: "100%", width: "100%", border: 0 }}
        />
    );
}
